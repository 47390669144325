import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { gql } from "graphql-tag";
import {
  CustomerForgottenPasswordResponseUnion,
  Customer,
  CustomerLoginResponse,
  CustomerResetPasswordResponseUnion,
  MutationForgottenPasswordArgs,
  CustomerRegistrationResponse,
  MutationLoginArgs,
  MutationResetPasswordArgs,
  MutationRegisterCustomerArgs,
  QueryIsValidResetIdArgs,
  AuthenticatedCustomer,
  IsEmailRegisteredQueryVariables,
} from "@generated/types";
import {
  loginSelector,
  bookingPersistedStateSelector,
  gvBasketIdPersistedStateSelector,
} from "../selectors";
import {
  createLogin,
  createAutoLogin,
  checkEmailRegistration,
  checkEmailRegistrationSuccess,
  createLoginSuccess,
  createLoginFail,
  createAutoLoginFail,
  setLogoutOutStatus,
  setCustomerId,
  setToken,
  createRegister,
  createRegisterFail,
  createRegisterSuccess,
  isValidResetId as resetIdSlice,
  isValidResetIdFail,
  isValidResetIdSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess,
  forgotPassword,
  forgotPasswordFail,
  forgotPasswordSuccess,
  createAutoLoginSuccess,
  createLogoutFromSession,
  addCustomerToBooking,
  addCustomerToGvBasket,
  resetAppState,
  loginRememberMe,
  loginRememberMeSuccess,
  loginRememberMeFailed,
  validateXFhAuthCookie,
  validateXFhAuthCookieSuccess,
  validateXFhAuthCookieFailed,
} from "../slices";
// import loginMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/login.gql";
// import registerMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/registerCustomer.gql";
// import forgotPasswordMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/forgottenPassword.gql";
// import currentCustomer from "../../graphql/bsl/gql-generated/dot-gql/queries/currentCustomer.gql";
// import resetPasswordMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/resetPassword.gql";
// import isValidResetId from "../../graphql/bsl/gql-generated/dot-gql/queries/isValidResetId.gql";
// import isEmailRegistered from "../../graphql/bsl/gql-generated/dot-gql/queries/isEmailRegistered.gql";
import { getBSLUrl, loadGraphQLMutation, loadGraphQLQuery } from "../../utils";
import { postSSTLoginRegisterEvent } from "src/utils/postsst";
import { FHEventType } from "src/interfaces/serverSideTracking";

function* loginSaga(action: ReturnType<typeof createLogin>) {
  const dynamicLoginMutation = yield call(loadGraphQLMutation, "login");
  const { request, rememberMe, eventType } = action.payload;
  const loginCall = () =>
    apolloClient.mutate<{ login: CustomerLoginResponse }, MutationLoginArgs>({
      mutation: gql`
        ${dynamicLoginMutation}
      `,
      fetchPolicy: "no-cache",
      variables: {
        request,
      },
    });

  try {
    const loginResponse = yield call(loginCall);
    const loginData = loginResponse?.data?.login;

    switch (loginData?.__typename) {
      case "AuthenticatedCustomer": {
        yield all([
          put(
            createLoginSuccess({
              customer: loginData.customer,
              token: loginData.token,
              eventType: eventType,
            }),
          ),
          rememberMe &&
            put(
              loginRememberMe({
                token: (loginResponse?.data?.login as AuthenticatedCustomer)
                  .token,
                uid: (loginResponse?.data?.login as AuthenticatedCustomer)
                  .customer.id,
              }),
            ),
        ]);
        break;
      }
      case "AuthenticationError": {
        throw Error(loginData.message);
      }
      default: {
        throw new Error("Login failed with unknown error.");
      }
    }
  } catch (error: any) {
    yield put(createLoginFail(error.message));
  }
}

function* attemptAutoLoginSaga(action: ReturnType<typeof createAutoLogin>) {
  const dynamicCurrentCustomerQuery = yield call(
    loadGraphQLQuery,
    "currentCustomer",
  );

  const currentCustomerCall = () =>
    apolloClient.mutate<{ currentCustomer: Customer }>({
      mutation: gql`
        ${dynamicCurrentCustomerQuery}
      `,
      fetchPolicy: "no-cache",
      variables: {},
    });

  try {
    const currentCustomerResponse = yield call(currentCustomerCall);
    const currentCustomerData = currentCustomerResponse?.data?.currentCustomer;

    switch (currentCustomerData?.__typename) {
      case "Customer": {
        yield all([
          put(
            createLoginSuccess({
              customer: currentCustomerData,
              token: "",
            }),
          ),
          put(createAutoLoginSuccess()),
        ]);
        break;
      }
      default: {
        throw new Error("Auto Login failed.");
      }
    }
  } catch (error: any) {
    yield put(createAutoLoginFail());
    // appInsights.trackException(error);
  }
}

function* loginRememberSaga(action: ReturnType<typeof loginRememberMe>) {
  const { token, uid } = action.payload;
  const headers = new Headers();
  headers.append("Authorization", "Bearer " + token);
  headers.append("Accept", "application/json, text/plain, */*");
  headers.append("Content-Type", "application/json");
  try {
    yield fetch(`${getBSLUrl()}/auth/signin/`, {
      method: "POST",
      headers,
    });
    yield put(loginRememberMeSuccess({ uid }));
  } catch (e: any) {
    yield put(loginRememberMeFailed());
  }
}

function* loginSuccessfulSaga(action: ReturnType<typeof createLoginSuccess>) {
  const { isBookingJourney, isGiftVoucherJourney } =
    yield select(loginSelector);
  const { customer, token, eventType } = action.payload;
  const event: FHEventType = eventType || FHEventType.login;
  const customerId = customer.id;

  postSSTLoginRegisterEvent(event, customerId);

  const { bookingId } = yield select(bookingPersistedStateSelector);
  const { basketId } = yield select(gvBasketIdPersistedStateSelector);

  if (customerId && token) {
    yield put(setToken(token));
    yield put(setCustomerId(customerId));
  }

  if (isBookingJourney && bookingId && customerId) {
    yield put(
      addCustomerToBooking({
        bookingId,
        customerId,
      }),
    );
  }

  if (isGiftVoucherJourney && basketId && customerId) {
    yield put(
      addCustomerToGvBasket({
        basketId: Number(basketId),
        customerId,
      }),
    );
  }
}

/* commented for CSR reference.
function* autoLoginSuccessSaga(action: any) {
  // window.location.pathname = window.location.origin + "/dashboard";
}
*/
function* loginFromSessionSaga(action: ReturnType<typeof createLogin>) {
  const loginData = yield select(loginSelector);

  if (loginData.loginRequest) {
    yield put(
      createLogin({
        request: loginData.loginRequest,
      }),
    );
  }
}

function* logoutFromSessionSaga(
  action: ReturnType<typeof createLogoutFromSession>,
) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const headers = new Headers();
    headers.append("X-FH-Auth", `Bearer ${token}`);
    sessionStorage.clear();
    localStorage.clear();
    try {
      yield fetch(`${getBSLUrl()}/auth/signout/`, {
        method: "GET",
        headers,
      });

      // Reset app state
      yield put(resetAppState());

      // Let app know user has logged out recently
      yield put(setLogoutOutStatus(true));
    } catch (e: any) {
      console.error(e);
      // appInsights.trackException(e);
    }
  } else {
    const error: any = "Unable to signout due to missing token.";
    // appInsights.trackException(error);
  }
}

// Register
function* registerSaga(action: ReturnType<typeof createRegister>) {
  const dynamicRegisterMutation = yield call(
    loadGraphQLMutation,
    "registerCustomer",
  );
  const registerCall = () =>
    apolloClient.mutate<
      { registerCustomer: CustomerRegistrationResponse },
      MutationRegisterCustomerArgs
    >({
      mutation: gql`
        ${dynamicRegisterMutation}
      `,
      fetchPolicy: "no-cache",
      variables: {
        request: action.payload,
      },
    });

  try {
    const response = yield call(registerCall);
    const registerCustomer = response?.data?.registerCustomer;
    switch (registerCustomer?.__typename) {
      case "Customer":
        yield put(createRegisterSuccess(registerCustomer));
        yield put(
          createLogin({
            request: {
              email: action.payload.email,
              password: action.payload.password,
            },
            eventType: FHEventType.register,
          }),
        );
        break;
      case "DuplicateEntityError":
        throw Error(registerCustomer.message);
      default:
        throw Error("Failed to register customer");
    }
  } catch (error: any) {
    yield put(createRegisterFail(error.message));
    // appInsights.trackException(error);
  }
}

// Forgot password
function* forgotPasswordSaga(action: ReturnType<typeof forgotPassword>) {
  const dynamicforgotPasswordMutation = yield call(
    loadGraphQLMutation,
    "forgottenPassword",
  );
  const forgotPasswordCall = () =>
    apolloClient.mutate<
      { forgottenPassword: CustomerForgottenPasswordResponseUnion },
      MutationForgottenPasswordArgs
    >({
      mutation: gql`
        ${dynamicforgotPasswordMutation}
      `,
      variables: {
        request: action.payload,
      },
    });

  try {
    const response = yield call(forgotPasswordCall);
    const data = response.data?.forgottenPassword;

    switch (data?.__typename) {
      case "CustomerForgottenPasswordResponse": {
        if (data.success) {
          yield put(forgotPasswordSuccess(data));
          break;
        } else {
          throw Error("An error occurred. Please try again");
        }
      }
      case "ForgottenPasswordError": {
        throw Error(data.message);
      }
      default: {
        throw Error("An unknown error occurred.");
      }
    }
  } catch (error: any) {
    yield put(forgotPasswordFail(error.message));
    // appInsights.trackException(error);
  }
}

// Reset password
function* resetPasswordSaga(action: ReturnType<typeof resetPassword>) {
  const dynamicResetPasswordMutation = yield call(
    loadGraphQLMutation,
    "resetPassword",
  );
  const resetPasswordCall = () =>
    apolloClient.mutate<
      { resetPassword: CustomerResetPasswordResponseUnion },
      MutationResetPasswordArgs
    >({
      mutation: gql`
        ${dynamicResetPasswordMutation}
      `,
      variables: {
        request: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(resetPasswordCall);
    const data = response.data?.resetPassword;

    switch (data?.__typename) {
      case "CustomerResetPasswordResponse": {
        if (data.success) {
          yield put(resetPasswordSuccess(data));
          break;
        } else {
          throw Error("An error occurred. Please try again");
        }
      }
      case "PasswordResetError": {
        throw Error(data.message);
      }
      default: {
        throw Error("An unknown error occurred.");
      }
    }
  } catch (error: any) {
    yield put(resetPasswordFail(error.message));
    // appInsights.trackException(error);
  }
}

function* isValidResetIdSaga(action: ReturnType<typeof resetIdSlice>) {
  const dynamicIsValidQuery = yield call(loadGraphQLQuery, "isValidResetId");
  const isValidResetIdCall = () =>
    apolloClient.query<{ isValidResetId: boolean }, QueryIsValidResetIdArgs>({
      query: gql`
        ${dynamicIsValidQuery}
      `,
      variables: { resetId: action.payload },
      fetchPolicy: "no-cache",
    });

  try {
    const getisValidResetIdResponse = yield call(isValidResetIdCall);
    const { data } = getisValidResetIdResponse;

    if (!!data) {
      yield put(isValidResetIdSuccess(data.isValidResetId));
    } else {
      yield put(isValidResetIdFail("Failed to get is valid reset id"));
    }
  } catch (error: any) {
    yield put(isValidResetIdFail(error));
    // appInsights.trackException(error);
  }
}

function* validateXFhAuthCookieSaga(
  action: ReturnType<typeof validateXFhAuthCookie>,
) {
  const { uid } = action.payload;
  const authCall = async () =>
    await fetch("/api/fhauth", {
      method: "POST",
    }).then((response) => response.json());
  try {
    const authCallResponse: { status: boolean } = yield call(authCall);
    if (authCallResponse.status)
      yield put(validateXFhAuthCookieSuccess({ uid }));
    else yield put(validateXFhAuthCookieFailed({ uid }));
  } catch (e: any) {
    yield put(validateXFhAuthCookieFailed({ uid }));
  }
}

export function* authInitSaga() {
  yield put(createAutoLogin());
}

export function* emailRegistrationCheckSaga(
  action: ReturnType<typeof checkEmailRegistration>,
) {
  const dynamicIsEmailRegistered = yield call(
    loadGraphQLQuery,
    "isEmailRegistered",
  );
  const isEmailRegisteredCall = () => {
    return apolloClient.query<
      { isEmailRegistered: string },
      IsEmailRegisteredQueryVariables
    >({
      query: gql`
        ${dynamicIsEmailRegistered}
      `,
      variables: { emailAddress: action.payload },
      fetchPolicy: "no-cache",
    });
  };

  try {
    const emailIsRegistered = yield call(isEmailRegisteredCall);
    const { data } = emailIsRegistered;
    if (typeof data.isEmailRegistered === "boolean") {
      yield put(checkEmailRegistrationSuccess(data.isEmailRegistered));
    }
  } catch (error: any) {
    yield put(createLoginFail(error.message));
  }
}

export function* authSagas() {
  yield all([
    takeEvery("login/createLogin", loginSaga),
    takeLatest("login/createAutoLogin", attemptAutoLoginSaga),
    takeLatest("auth/loginRememberMe", loginRememberSaga),
    takeLatest("auth/validateXFhAuthCookie", validateXFhAuthCookieSaga),
    takeLatest("login/createLoginSuccess", loginSuccessfulSaga),
    takeLatest("login/createLoginFromSession", loginFromSessionSaga),
    takeLatest("login/createLogoutFromSession", logoutFromSessionSaga),
    takeEvery("register/createRegister", registerSaga),
    takeEvery("forgotPassword/forgotPassword", forgotPasswordSaga),
    takeEvery("resetPassword/resetPassword", resetPasswordSaga),
    takeLatest("resetPassword/isValidResetId", isValidResetIdSaga),
    takeLatest("login/checkEmailRegistration", emailRegistrationCheckSaga),
  ]);
}
