import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { gql } from "graphql-tag";
import { loadGraphQLQuery } from "src/utils";
import { SiteSettingsResponse } from "@generated/types";
import {
  getSiteSettingsAction,
  getSiteSettingsFailAction,
  getSiteSettingsSuccessAction,
  setBookingStepsAdditionalData,
} from "../slices";
import { bookingSearchSelectedLocationIds } from "../selectors";

function* getSiteSettingsSaga(
  action: ReturnType<typeof getSiteSettingsAction>,
) {
  const dynamicSiteMapQuery = yield call(loadGraphQLQuery, "siteSettings");
  const getSiteSettingsCall = () =>
    apolloClient.query<{
      siteSettings: SiteSettingsResponse;
    }>({
      query: gql`
        ${dynamicSiteMapQuery}
      `,
      fetchPolicy: "no-cache",
    });

  try {
    const getSiteSettingsResponse = yield call(getSiteSettingsCall);

    const { data } = getSiteSettingsResponse;

    switch (data?.siteSettings.__typename) {
      case "SiteSettingsResponse":
        yield put(
          getSiteSettingsSuccessAction(
            data?.siteSettings as Required<SiteSettingsResponse>,
          ),
        );
        break;
    }
  } catch (error) {
    yield put(
      getSiteSettingsFailAction(
        error?.message || "Failed to get site settings",
      ),
    );
  }
}

function* setAdditionalBookingJourneyDataSaga() {
  const locationIdsFromSelector = yield select(
    bookingSearchSelectedLocationIds,
  );

  let locationIds: string[];
  if (locationIdsFromSelector?.[0]?.length > 0)
    locationIds = locationIdsFromSelector[0];
  else locationIds = locationIdsFromSelector;

  yield put(
    setBookingStepsAdditionalData(
      locationIds?.length === 1
        ? { locationId: locationIds[0] }
        : { locationIds },
    ),
  );
}

export function* siteSettingsSagas() {
  yield all([
    takeLatest("siteSettings/getSiteSettingsAction", getSiteSettingsSaga),
    takeLatest(
      "siteSettings/setActiveBookingStepByUrlAction",
      setAdditionalBookingJourneyDataSaga,
    ),
  ]);
}
