import { call, put, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import { Customer } from "@generated/types";
import { getCustomer, getCustomerSuccess, getCustomerFail } from "../slices";
import { loadGraphQLQuery } from "src/utils";

function* getCustomerSaga(action: ReturnType<typeof getCustomer>) {
  const dynamicCurrentCustomerQuery = yield call(
    loadGraphQLQuery,
    "currentCustomer",
  );
  const token = action.payload;
  if (!!token) {
    const getCustomerCall = () =>
      apolloClient.query<{ currentCustomer: Customer }>({
        query: gql`
          ${dynamicCurrentCustomerQuery}
        `,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      });

    try {
      const response = yield call(getCustomerCall);
      const { data, errors } = response;

      if (data?.currentCustomer) {
        yield put(getCustomerSuccess(data.currentCustomer));
      } else {
        throw Error((errors && errors[0].message) || "Failed get customer");
      }
    } catch (error: any) {
      yield put(getCustomerFail(error.message));
    }
  } else {
    const error: any = "Unable to get Customer due to missing token.";
    yield put(getCustomerFail(error));
  }
}

export default function* accountSagas() {
  yield takeLatest("account/getCustomer", getCustomerSaga);
}
