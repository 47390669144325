import { gql } from "graphql-tag";
import { MutationAddCustomerToBookingArgs } from "@generated/types";
import {
  addCustomerToBookingFail,
  addCustomerToBookingSuccess,
  addCustomerToBooking,
} from "../slices";
import { call, put, takeEvery, all } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { loadGraphQLMutation } from "src/utils";

function* addCustomerToBookingSaga(
  action: ReturnType<typeof addCustomerToBooking>,
) {
  const dynamicAddCustomerToBooking = yield call(
    loadGraphQLMutation,
    "addCustomerToBooking",
  );
  const addCustomerToBookingCall = () =>
    apolloClient.mutate<
      { addCustomerToBooking: boolean },
      MutationAddCustomerToBookingArgs
    >({
      mutation: gql`
        ${dynamicAddCustomerToBooking}
      `,
      variables: action.payload,
    });

  try {
    const response = yield call(addCustomerToBookingCall);
    const { addCustomerToBooking } = response.data!;

    if (addCustomerToBooking) {
      yield put(addCustomerToBookingSuccess(addCustomerToBooking));
    } else {
      throw Error("Failed to add customer to booking");
    }
  } catch (error) {
    yield put(addCustomerToBookingFail(error.message));
    // appInsights.trackException(error);
  }
}

export default function* bookingCustomerSagas() {
  yield all([
    takeEvery(
      "bookingCustomerAdd/addCustomerToBooking",
      addCustomerToBookingSaga,
    ),
  ]);
}
