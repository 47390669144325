import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  LocationMapPropertiesResponseUnion,
  QueryLocationMapPropertiesArgs,
} from "@generated/types";
import {
  getLocationMapProperties,
  getLocationMapPropertiesFail,
  getLocationMapPropertiesSuccess,
} from "../slices";
import { loadGraphQLQuery } from "../../utils";

function* getLocationMapPropertiesSaga(
  action: ReturnType<typeof getLocationMapProperties>,
) {
  const dynamicLocationProperties = yield call(
    loadGraphQLQuery,
    "locationMapProperties",
  );
  const locationMapPropertiesCall = () =>
    apolloClient.query<
      { locationMapProperties: LocationMapPropertiesResponseUnion },
      QueryLocationMapPropertiesArgs
    >({
      query: gql`
        ${dynamicLocationProperties}
      `,
      variables: action.payload,
    });

  try {
    const response = yield call(locationMapPropertiesCall);
    const locationMapProperties = response.data?.locationMapProperties;

    switch (locationMapProperties?.__typename) {
      case "LocationMapPropertiesResponse":
        yield put(getLocationMapPropertiesSuccess(locationMapProperties));
        break;
      case "SystemError":
        throw Error(locationMapProperties?.message || "");
      default:
        throw Error("Failed to get location map properties");
    }
  } catch (error) {
    yield put(getLocationMapPropertiesFail(error.message));
    // appInsights.trackException(error);
  }
}

export default function* locationMapSagas() {
  yield all([
    takeLatest(
      "locationMap/getLocationMapProperties",
      getLocationMapPropertiesSaga,
    ),
  ]);
}
