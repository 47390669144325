import { all, call, put, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  getBlogsFailAction,
  getBlogsSuccessAction,
  getBlogsAction,
} from "../slices";
import { loadGraphQLQuery } from "src/utils";
import { QueryBlogPostsArgs, BlogPostsResponse } from "@generated/types";

function* getBlogsSaga(action: ReturnType<typeof getBlogsAction>) {
  const dynamicBlogsPostQuery = yield call(loadGraphQLQuery, "blogPosts");
  const getBlogsCall = () =>
    apolloClient.query<{ blogPosts: BlogPostsResponse }, QueryBlogPostsArgs>({
      query: gql`
        ${dynamicBlogsPostQuery}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
      context: {
        timeout: 120000,
      },
    });

  try {
    const getBlogsResponse = yield call(getBlogsCall);
    const { data } = getBlogsResponse;
    switch (data?.blogPosts.__typename) {
      case "BlogPostsResponse":
        yield put(getBlogsSuccessAction(data?.blogPosts));
        break;
      default:
        break;
    }
  } catch (error: any) {
    yield put(getBlogsFailAction(error?.message || "Failed to get Blogs."));
  }
}

export function* blogSagas() {
  yield all([takeLatest("blogs/getBlogsAction", getBlogsSaga)]);
}
