import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  loadGraphQLMutation,
  loadCustomGraphQLQuery,
  loadCustomGraphQLMutation,
} from "src/utils";
import { getCookie } from "cookies-next";

import {
  AddSpecificCabinResponseUnion,
  AvailableCabinsResponseUnion,
  BookingSummary_S as BookingSummary,
  BookingSummaryResponseUnion,
  CabinEssentialPanel_S as CabinEssentialPanel,
  CabinInfo,
  CabinPanelSummaryResponse,
  CabinTypesAtLocationResponseUnion,
  CreateReservationFromReferralResponseUnion,
  GenericCabinTypesResponseUnion,
  MutationAddCabinArgs,
  MutationAddSpecificCabinArgs,
  MutationCreateReservationFromReferralArgs,
  MutationRemoveSpecificCabinArgs,
  MutationSwapSpecificCabinArgs,
  QueryAvailableCabinsForReservationArgs,
  QueryCabinAvailabilityArgs,
  QueryCabinInfoFromCabinIdArgs,
  QueryCabinTypesAtLocationArgs,
  QueryRefreshBookingSummaryArgs,
} from "@generated/types";

import {
  getAllCabinTypes,
  getAllCabinTypesSuccess,
  getAllCabinTypesFail,
  addSpecificCabin,
  addSpecificCabinSuccess,
  addSpecificCabinFail,
  swapSpecificCabin,
  swapSpecificCabinSuccess,
  swapSpecificCabinFail,
  removeSpecificCabin,
  removeSpecificCabinSuccess,
  removeSpecificCabinFail,
  getCabin,
  setCabinSearchFilter,
  getCabinFail,
  getCabinSuccess,
  createCabinSearch,
  createCabinSearchRedirect,
  getAvailableCabinsForReservation,
  setSelectedCabinForReservation,
  getAvailableCabinsForReservationSuccess,
  getAvailableCabinsForReservationFail,
  getCabinTypesAtLocation,
  getCabinTypesAtLocationSuccess,
  getCabinTypesAtLocationFail,
  getConfirmedBookingCabinEssentialsSuccessAction,
  getConfirmedBookingCabinEssentialsFailAction,
  getConfirmedBookingCabinEssentialsAction,
  setCabinReservationId,
  setBookingId,
  setEssentialExtras,
  getBookingConfirmationAction,
  addExtra,
  getExtras,
  viewExtraDetailsWithSticky,
  addCabinFail,
  addCabinSuccess,
  addCabin,
  getRefreshBookingSummaryFailAction,
  getRefreshBookingSummaryAction,
  getRefreshBookingSummarySuccessAction,
  initializeAddCabin,
  getCabinInfo,
  getCabinInfoFail,
  getCabinInfoSuccess,
  getReservationFromReferral,
  getReservationFromReferralSuccess,
  getReservationFromReferralFail,
  resetMandatoryExtraInteractions,
} from "../slices";
import {
  reservationStateSelector,
  cabinFilterSelector,
  cabinSortSelector,
  useBookingQueryStringSelector,
  bookingSearchSelector,
  siteSettingsSelector,
  loginSelector,
  extraListSelector,
  getReservationFromReferralSelector,
} from "../selectors";
import moment from "moment";
import { StepperTag } from "../../interfaces/booking";
import { getBookingStepByTag, isGuid } from "../../utils";
import {
  getQueryAsStringArray,
  isDateNowOrInFuture,
  isValidArrayOfGuids,
} from "../../utils/common";
import { FHCookies } from "src/interfaces/cookies";

// Search Cabin
function* getCabinSaga(action: ReturnType<typeof getCabin>) {
  const dynamicCabinAvailability = yield call(
    loadCustomGraphQLQuery,
    "cabinAvailability",
  );
  const getCabinCall = () =>
    apolloClient.query<
      { cabinAvailability: CabinPanelSummaryResponse },
      QueryCabinAvailabilityArgs
    >({
      query: gql`
        ${dynamicCabinAvailability}
      `,
      variables: action.payload.cabinQuery,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getCabinCall);
    const { cabinAvailability } = response.data!;

    if (cabinAvailability) {
      yield put(
        getCabinSuccess({
          summaryResponse: cabinAvailability,
          filtered: action.payload.filtered,
        }),
      );
    } else {
      throw Error("Failed to get available cabins");
    }
  } catch (error: any) {
    yield put(getCabinFail(error?.message));
    // appInsights.trackException(error);
  }
}

// Add Cabin
function* initializeAddCabinSaga(
  action: ReturnType<typeof initializeAddCabin>,
) {
  const {
    cabinId,
    petFriendly,
    price,
    priceCheck,
    priceExpiry,
    numberOfBedrooms,
    isDda: disabledAccess,
  } = yield select(reservationStateSelector);
  const experiments = action.payload;
  const {
    dateRange,
    selectedLocationIds: selectedLocations,
    promoCode: discountCode,
    bookingChannelId,
  } = yield select(bookingSearchSelector);
  const { bookingSearch } = yield select(getReservationFromReferralSelector);
  const { data: customerDetails } = yield select(loginSelector);
  const customerId = customerDetails?.customer.id;

  const startDate = moment(dateRange.startDateISO);
  const endDate = moment(dateRange.endDateISO);
  const selectedLocation = selectedLocations?.[0]?.toString();
  const interstitalTrackingId = getCookie(FHCookies.interstitalTracking)
    ? +getCookie(FHCookies.interstitalTracking)
    : undefined;

  yield put(
    addCabin({
      locationId: selectedLocation,
      cabinTypeId: cabinId, // todo: Why are we passing cabinId and not cabinTypeId?
      bookingChannelId,
      disabledAccess: disabledAccess as boolean,
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD"),
      numberOfBedrooms: numberOfBedrooms as number,
      petFriendly: petFriendly as boolean,
      price: price.price,
      discountPrice: price.discountPrice,
      standardPrice: price.standardPrice,
      tacticalPrice: price.tacticalPrice,
      channelPrice: price.channelPrice,
      cabinSellingPrice: price.cabinSellingPrice,
      priceCheck,
      priceExpiry,
      discountCode,
      customerId: customerId,
      success: true,
      trackingId: interstitalTrackingId,
      experiments: !!experiments?.reduceReservationWindow ? "506" : undefined,
    }),
  );
}

function* addCabinSaga(action: ReturnType<typeof addCabin>) {
  const dynamicAddMutation = yield call(loadCustomGraphQLMutation, "add-cabin");
  const addCabinCall = () =>
    apolloClient.mutate<{ addCabin: BookingSummary }, MutationAddCabinArgs>({
      mutation: gql`
        ${dynamicAddMutation}
      `,
      variables: {
        reservation: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(addCabinCall);

    const { addCabin } = response.data!;
    if (addCabin.success) {
      yield put(addCabinSuccess(addCabin));
    } else {
      throw Error("Failed to add cabin");
    }
  } catch (error: any) {
    yield put(addCabinFail(error?.message));
    // appInsights.trackException(error);
  }
}

// Specific Cabin
function* addSpecificCabinSaga(action: ReturnType<typeof addSpecificCabin>) {
  const dynamicAddSpecificCabin = yield call(
    loadGraphQLMutation,
    "addSpecificCabin",
  );
  const addSpecificCabinCall = () =>
    apolloClient.mutate<
      { addSpecificCabin: AddSpecificCabinResponseUnion },
      MutationAddSpecificCabinArgs
    >({
      mutation: gql`
        ${dynamicAddSpecificCabin}
      `,
      variables: action.payload.addSpecificCabinInput,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(addSpecificCabinCall);

    const addSpecificCabin = response.data?.addSpecificCabin;

    switch (addSpecificCabin?.__typename) {
      case "AddSpecificCabinResponse":
        if (action.payload.isPostBookingJourney) {
          yield put(
            viewExtraDetailsWithSticky(
              action.payload.addSpecificCabinInput.cabinReservationId,
            ),
          );
        }
        yield put(
          addSpecificCabinSuccess({
            addSpecificCabinResponse: addSpecificCabin,
            isPostBookingJourney: action.payload.isPostBookingJourney,
          }),
        );
        yield put(
          setSelectedCabinForReservation({
            cabinId: action.payload.addSpecificCabinInput.cabinId,
          }),
        );
        break;
      case "SystemError":
        throw Error(addSpecificCabin.message || "Failed to add cabin");
      default:
        throw Error("Failed to add cabin");
    }
  } catch (error: any) {
    yield put(addSpecificCabinFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* removeSpecificCabinSaga(
  action: ReturnType<typeof removeSpecificCabin>,
) {
  const dynamicRemoveSpecificCabin = yield call(
    loadGraphQLMutation,
    "removeSpecificCabin",
  );
  const removeSpecificCabinCall = () =>
    apolloClient.mutate<
      { removeSpecificCabin: AddSpecificCabinResponseUnion },
      MutationRemoveSpecificCabinArgs
    >({
      mutation: gql`
        ${dynamicRemoveSpecificCabin}
      `,
      variables: action.payload.removeSpecificCabinInput,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(removeSpecificCabinCall);

    const removeSpecificCabin = response.data?.removeSpecificCabin;

    switch (removeSpecificCabin?.__typename) {
      case "AddSpecificCabinResponse":
        yield put(
          removeSpecificCabinSuccess({
            removeSpecificCabinResponse: removeSpecificCabin,
            isPostBookingJourney: action.payload.isPostBookingJourney,
          }),
        );
        yield put(setSelectedCabinForReservation({ cabinId: null }));
        break;
      case "SystemError":
        throw Error(removeSpecificCabin.message || "Failed to remove cabin");
      default:
        throw Error("Failed to remove cabin");
    }
  } catch (error: any) {
    yield put(removeSpecificCabinFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* swapSpecificCabinSaga(action: ReturnType<typeof swapSpecificCabin>) {
  const dynamicSwapSpecificCabin = yield call(
    loadGraphQLMutation,
    "swapSpecificCabin",
  );
  const swapSpecificCabinCall = () =>
    apolloClient.mutate<
      { swapSpecificCabin: AddSpecificCabinResponseUnion },
      MutationSwapSpecificCabinArgs
    >({
      mutation: gql`
        ${dynamicSwapSpecificCabin}
      `,
      variables: action.payload.swapSpecificCabinInput,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(swapSpecificCabinCall);

    const swapSpecificCabin = response.data?.swapSpecificCabin;

    switch (swapSpecificCabin?.__typename) {
      case "AddSpecificCabinResponse":
        yield put(
          swapSpecificCabinSuccess({
            swapSpecificCabinResponse: swapSpecificCabin,
            isPostBookingJourney: action.payload.isPostBookingJourney,
          }),
        );
        yield put(
          setSelectedCabinForReservation({
            cabinId: action.payload.swapSpecificCabinInput.cabinId,
          }),
        );
        break;
      case "SystemError":
        throw Error(swapSpecificCabin.message || "Failed to remove cabin");
      default:
        throw Error("Failed to remove cabin");
    }
  } catch (error: any) {
    yield put(swapSpecificCabinFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* clearMandatoryExtrasInteractedWithOnCabinChange(
  action: ReturnType<typeof addCabinSuccess>,
) {
  yield put(resetMandatoryExtraInteractions());
}

function* addPetsToBooking(action: ReturnType<typeof addCabinSuccess>) {
  const searchData = yield select(bookingSearchSelector);
  const numberOfPetsInSearch = searchData.guests.pets;
  const petOption = action.payload.cabinEssentialPanels
    ?.flatMap((extra) => extra?.essentialExtras)
    .flatMap((essentialExtra) => essentialExtra?.extraOptions)
    .find((extra) => extra?.isPet);

  if (numberOfPetsInSearch > 0 && petOption) {
    yield put(
      addExtra({
        addExtraInput: [
          {
            cabinReservationId: action.payload.cabinReservationId,
            extraOptionId: petOption.extraOptionId,
            quantity: numberOfPetsInSearch,
            price: petOption.extraOptionPrice,
            priceCheck: petOption.priceCheck,
            priceExpiry: petOption.priceExpiry,
            extraId: petOption.extraId,
          },
        ],
        showSticky: false,
        isPostBookingJourney: true,
      }),
    );
  }
}

function* addCabinSuccessSaga(
  action:
    | ReturnType<typeof addCabinSuccess>
    | ReturnType<typeof getReservationFromReferralSuccess>,
) {
  const { cabinReservationId, bookingId, cabinEssentialPanels, success } =
    action.payload;
  if (!!success) {
    yield put(setCabinReservationId(cabinReservationId));
    yield put(setBookingId(bookingId));
    yield put(
      setEssentialExtras(cabinEssentialPanels as CabinEssentialPanel[]),
    );
    yield put(getBookingConfirmationAction({ bookingId }));
  }
}

// All Cabin Types
function* getAllCabinTypesSaga(action: ReturnType<typeof getAllCabinTypes>) {
  const dynamicAllCabinTypes = yield call(
    loadCustomGraphQLQuery,
    "allCabinTypes",
  );
  const getAllCabinTypesCall = () =>
    apolloClient.query<{ allCabinTypes: GenericCabinTypesResponseUnion }>({
      query: gql`
        ${dynamicAllCabinTypes}
      `,
      fetchPolicy: "no-cache",
      context: {
        timeout: 120000, // it's a temporary timeout fix and will be removed after the bsl fix.
      },
    });

  try {
    const response = yield call(getAllCabinTypesCall);

    const allCabinTypes = response.data?.allCabinTypes;

    switch (allCabinTypes?.__typename) {
      case "GenericCabinTypesResponse": {
        yield put(getAllCabinTypesSuccess(allCabinTypes));
        break;
      }
      case "SystemError": {
        throw Error(allCabinTypes?.message || "");
      }
      default: {
        throw Error("Failed to get cabin types");
      }
    }
  } catch (error: any) {
    yield put(getAllCabinTypesFail(error?.message));
    // appInsights.trackException(error);
  }
}

// Cabin Types At Location
function* getCabinTypesAtLocationSaga(
  action: ReturnType<typeof getCabinTypesAtLocation>,
) {
  const dynamicCabinTypesAtLocation = yield call(
    loadCustomGraphQLQuery,
    "cabinTypesAtLocation",
  );
  const getCabinTypesAtLocationCall = () =>
    apolloClient.query<
      { cabinTypesAtLocation: CabinTypesAtLocationResponseUnion },
      QueryCabinTypesAtLocationArgs
    >({
      query: gql`
        ${dynamicCabinTypesAtLocation}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getCabinTypesAtLocationCall);

    const cabinTypesAtLocation = response.data?.cabinTypesAtLocation;

    switch (cabinTypesAtLocation?.__typename) {
      case "CabinTypesAtLocationResponse": {
        yield put(getCabinTypesAtLocationSuccess(cabinTypesAtLocation));
        break;
      }
      case "SystemError": {
        throw Error(cabinTypesAtLocation?.message || "");
      }
      default: {
        throw Error("Failed to get cabin types at location");
      }
    }
  } catch (error: any) {
    yield put(getCabinTypesAtLocationFail(error?.message));
    // appInsights.trackException(error);
  }
}

// Cabins For Reservation
function* getAvailableCabinsForReservationSaga(
  action: ReturnType<typeof getAvailableCabinsForReservation>,
) {
  const dynamicAvailableCabinForReservation = yield call(
    loadCustomGraphQLQuery,
    "availableCabinsForReservation",
  );
  const { data } = yield select(loginSelector);
  const getAvailableCabinsForReservationCall = () =>
    apolloClient.query<
      { availableCabinsForReservation: AvailableCabinsResponseUnion },
      QueryAvailableCabinsForReservationArgs
    >({
      query: gql`
        ${dynamicAvailableCabinForReservation}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getAvailableCabinsForReservationCall);

    const availableCabinsForReservation =
      response.data?.availableCabinsForReservation;

    switch (availableCabinsForReservation?.__typename) {
      case "AvailableCabinsResponse": {
        yield put(
          getAvailableCabinsForReservationSuccess(
            availableCabinsForReservation,
          ),
        );
        break;
      }
      case "SystemError": {
        throw Error(availableCabinsForReservation?.message || "");
      }
      default: {
        throw Error("Failed to get available cabins for reservation");
      }
    }
  } catch (error: any) {
    yield put(getAvailableCabinsForReservationFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* cabinSearchUpdatedSaga() {
  const {
    dda,
    guests,
    selectedLocationIds: bookingLocationIds,
    promoCode,
    dateRange: bookingDateRange,
  } = yield select(bookingSearchSelector);

  const cabinSort = yield select(cabinSortSelector);
  const queryData = yield select(useBookingQueryStringSelector);

  const { dateRange, selectedLocationIds } = queryData || {
    dateRange: bookingDateRange,
    selectedLocationIds: bookingLocationIds,
  };

  const startDate = moment(dateRange?.startDateISO);
  const endDate = moment(dateRange?.endDateISO);

  const locationIds =
    queryData?.selectedLocationIds?.length > 0
      ? selectedLocationIds
      : (selectedLocationIds as unknown as string[]) || [];

  const locationsValid = isValidArrayOfGuids(locationIds);
  const startDateValid =
    !!startDate && isDateNowOrInFuture(dateRange?.startDateISO);
  const endDateValid =
    isDateNowOrInFuture(dateRange?.endDateISO) &&
    !!startDate &&
    !!endDate &&
    startDate.isBefore(endDate);

  if (locationsValid && startDateValid && endDateValid) {
    yield put(
      getCabin({
        cabinQuery: {
          request: {
            cabinTypeIds: [],
            bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
            disabledAccess: dda,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : endDate,
            locationIds,
            numberOfAdults: guests.adults,
            numberOfBedrooms: guests.bedrooms,
            numberOfChildren: guests.children,
            numberOfPets: guests.pets,
            startDate:
              startDate !== null ? startDate.format("YYYY-MM-DD") : startDate,
            sortMethod: cabinSort,
            promoCode,
            bookingRecencyMaxHours: 24,
          },
        },
        filtered: false,
      }),
    );
  }
}

function* cabinSearchFilteredSaga(
  action: ReturnType<typeof setCabinSearchFilter>,
) {
  const {
    dda,
    guests,
    selectedLocationIds: selectedLocations,
    promoCode,
    dateRange,
  } = yield select(bookingSearchSelector);
  const cabinSort =
    action?.payload && !!action.payload.sortMethod
      ? action.payload.sortMethod
      : yield select(cabinSortSelector);
  const cabinFilters =
    action?.payload && !!action.payload.filters
      ? action.payload.filters
      : yield select(cabinFilterSelector);

  const startDate = moment(dateRange.startDateISO);
  const endDate = moment(dateRange.endDateISO);
  const locationIds = (selectedLocations as unknown as string[]) || [];

  yield put(
    getCabin({
      cabinQuery: {
        request: {
          cabinTypeIds: [],
          bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
          disabledAccess: dda,
          endDate: endDate ? endDate.format("YYYY-MM-DD") : endDate,
          locationIds,
          numberOfAdults: guests.adults,
          numberOfBedrooms: guests.bedrooms,
          numberOfChildren: guests.children,
          numberOfPets: guests.pets,
          startDate:
            startDate !== null ? startDate.format("YYYY-MM-DD") : startDate,
          sortMethod: cabinSort,
          promoCode,
          bookingRecencyMaxHours: 24,
        },
        selectedTagIds:
          !!cabinFilters && !!cabinFilters.length ? cabinFilters : undefined,
      },
      filtered: true,
    }),
  );
}

function* cabinSearchRedirectSaga(
  action: ReturnType<typeof createCabinSearchRedirect>,
) {
  const { l } = action.payload.query;
  const queryLocationIds = getQueryAsStringArray(l)?.filter(isGuid);
  const { selectedLocationIds } = yield select(bookingSearchSelector);
  const locationIds: string[] = queryLocationIds || selectedLocationIds || [];

  const { fhBookingSteps } = yield select(siteSettingsSelector);
  if (locationIds?.length >= 2) {
    const locationUrl = getBookingStepByTag(
      StepperTag.locations,
      fhBookingSteps,
    )?.url;
    locationUrl && action.payload.push(locationUrl);
  } else {
    yield put(createCabinSearch());
  }
}

function* refreshBookingSummarySaga(
  action: ReturnType<typeof getRefreshBookingSummaryAction>,
) {
  const dynamicGetRefreshBookingSummary = yield call(
    loadCustomGraphQLQuery,
    "refreshBookingSummary",
  );
  const { data } = yield select(loginSelector);
  const refreshBookingSummaryCall = () =>
    apolloClient.query<
      { refreshBookingSummary: BookingSummaryResponseUnion },
      QueryRefreshBookingSummaryArgs
    >({
      query: gql`
        ${dynamicGetRefreshBookingSummary}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(refreshBookingSummaryCall);

    const { refreshBookingSummary } = response.data!;

    switch (refreshBookingSummary.__typename) {
      case "BookingSummaryResponse":
        if (refreshBookingSummary?.bookingSummary)
          yield put(
            getRefreshBookingSummarySuccessAction(
              refreshBookingSummary?.bookingSummary,
            ),
          );
        break;
      case "SystemError":
        throw new Error(
          refreshBookingSummary?.message ||
            "ConfirmedBookingSummary non valid entity error",
        );
      default:
        break;
    }
  } catch (error: any) {
    yield put(getRefreshBookingSummaryFailAction(error?.message));
    // appInsights.trackException(error);
  }
}

function* getRefreshBookingSummarySuccessSaga(
  action: ReturnType<typeof getRefreshBookingSummarySuccessAction>,
) {
  const { cabinEssentialPanels } = action.payload;

  yield put(setEssentialExtras(cabinEssentialPanels as CabinEssentialPanel[]));
}

function* confirmedBookingCabinEssentialsSaga(
  action: ReturnType<typeof getConfirmedBookingCabinEssentialsAction>,
) {
  const dynamicGetRefreshBookingSummary = yield call(
    loadCustomGraphQLQuery,
    "refreshBookingSummary",
  );
  const { data } = yield select(loginSelector);
  const refreshBookingSummaryCall = () =>
    apolloClient.query<
      { refreshBookingSummary: BookingSummaryResponseUnion },
      QueryRefreshBookingSummaryArgs
    >({
      query: gql`
        ${dynamicGetRefreshBookingSummary}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
      fetchPolicy: "no-cache",
    });

  const isPostBookingJourney = action.payload.isPostBookingJourney;

  try {
    const response = yield call(refreshBookingSummaryCall);

    const { refreshBookingSummary } = response.data!;

    switch (refreshBookingSummary.__typename) {
      case "BookingSummaryResponse":
        if (refreshBookingSummary?.bookingSummary?.cabinEssentialPanels)
          yield put(
            getConfirmedBookingCabinEssentialsSuccessAction(
              (refreshBookingSummary?.bookingSummary?.cabinEssentialPanels ||
                []) as CabinEssentialPanel[],
            ),
          );
        const { list } = yield select(extraListSelector);

        if (list && list.getExtrasRequest && isPostBookingJourney) {
          yield put(
            getExtras({
              extrasQuery: list.getExtrasRequest,
              filtered: false,
            }),
          );
        }
        break;
      case "SystemError":
        throw new Error(
          refreshBookingSummary?.message ||
            "Failed to get confirmed booking cabin essential extras",
        );
      default:
        break;
    }
  } catch (error: any) {
    yield put(getConfirmedBookingCabinEssentialsFailAction(error?.message));
    // appInsights.trackException(error);
  }
}

function* getCabinInfoFromCabinIdSaga(action: ReturnType<typeof getCabinInfo>) {
  const dynamicCabinInfoFromCabinId = yield call(
    loadCustomGraphQLQuery,
    "cabinInfoFromCabinId",
  );
  const getCabinInfoFromCabinIdCall = () =>
    apolloClient.query<
      { cabinInfoFromCabinId: CabinInfo },
      QueryCabinInfoFromCabinIdArgs
    >({
      query: gql`
        ${dynamicCabinInfoFromCabinId}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getCabinInfoFromCabinIdCall);
    console.log(response);

    const cabinInfo = response.data?.cabinInfoFromCabinId;

    if (cabinInfo) {
      yield put(getCabinInfoSuccess(cabinInfo));
    } else {
      throw Error("Failed to get cabin info");
    }
  } catch (error) {
    yield put(getCabinInfoFail(error.message));
  }
}

function* getReservationFromReferralSaga(
  action: ReturnType<typeof getReservationFromReferral>,
) {
  const dynamicGetReservationFromReferral = yield call(
    loadCustomGraphQLMutation,
    "createReservationFromReferral",
  );
  const getReservationFromReferralCall = () =>
    apolloClient.query<
      {
        createReservationFromReferral: CreateReservationFromReferralResponseUnion;
      },
      MutationCreateReservationFromReferralArgs
    >({
      query: gql`
        ${dynamicGetReservationFromReferral}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getReservationFromReferralCall);
    const { createReservationFromReferral } = response.data!;

    switch (createReservationFromReferral.__typename) {
      case "BookingSummary_s":
        yield put(
          getReservationFromReferralSuccess(createReservationFromReferral),
        );
        break;
      case "SystemError":
        throw new Error(
          createReservationFromReferral?.message ||
            "Failed to create reservation from referrral",
        );
      default:
        break;
    }
  } catch (error: any) {
    yield put(getReservationFromReferralFail(error?.message));
  }
}

export default function* cabinSagas() {
  yield all([
    takeLatest("initialize/initializeAddCabin", initializeAddCabinSaga),
    takeLatest("cabinInfo/getCabinInfo", getCabinInfoFromCabinIdSaga),
    takeLatest(
      "reservationFromReferral/getReservationFromReferral",
      getReservationFromReferralSaga,
    ),
    takeLatest("cabinsSearch/createCabinSearch", cabinSearchUpdatedSaga),
    takeLatest(
      "cabinsSearch/createCabinSearchRedirect",
      cabinSearchRedirectSaga,
    ),
    takeLatest("cabinsSearch/getCabin", getCabinSaga),
    takeEvery("cabinAdd/addCabin", addCabinSaga),
    takeLatest("cabinAdd/addCabinSuccess", addPetsToBooking),
    takeLatest(
      "cabinAdd/addCabinSuccess",
      clearMandatoryExtrasInteractedWithOnCabinChange,
    ),
    takeEvery("cabinsSpecificAdd/addSpecificCabin", addSpecificCabinSaga),
    takeEvery(
      "cabinsSpecificRemove/removeSpecificCabin",
      removeSpecificCabinSaga,
    ),
    takeEvery("cabinsSpecificSwap/swapSpecificCabin", swapSpecificCabinSaga),
    takeLatest("cabinAdd/addCabinSuccess", addCabinSuccessSaga),
    takeLatest(
      "reservationFromReferral/getReservationFromReferralSuccess",
      addCabinSuccessSaga,
    ),
    takeLatest("cabinsSearch/getAllCabinTypes", getAllCabinTypesSaga),
    takeLatest(
      "cabinsSearch/getCabinTypesAtLocation",
      getCabinTypesAtLocationSaga,
    ),
    takeLatest(
      "cabinsReservation/getAvailableCabinsForReservation",
      getAvailableCabinsForReservationSaga,
    ),
    takeLatest("cabinsSearch/setCabinSearchFilter", cabinSearchFilteredSaga),
    takeLatest(
      "cabinAdd/getRefreshBookingSummaryAction",
      refreshBookingSummarySaga,
    ),
    takeLatest(
      "cabinAdd/getRefreshBookingSummarySuccessAction",
      getRefreshBookingSummarySuccessSaga,
    ),
    takeLatest(
      "confirmBookingCabinEssentials/getConfirmedBookingCabinEssentialsAction",
      confirmedBookingCabinEssentialsSaga,
    ),
  ]);
}
