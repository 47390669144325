import { call, put, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import { BreadcrumbsResponse } from "@generated/types";
import {
  getBreadcrumbFailAction,
  getBreadcrumbSuccessAction,
  getBreadcrumbAction,
} from "../slices";
import { loadGraphQLQuery } from "src/utils";

function* getBreadcrumbSaga(action: ReturnType<typeof getBreadcrumbAction>) {
  const dynamicBreadcrumbQuery = yield call(loadGraphQLQuery, "breadcrumbs");
  const getBreadcrumbCall = () =>
    apolloClient.query<{ breadcrumbs: BreadcrumbsResponse }>({
      query: gql`
        ${dynamicBreadcrumbQuery}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getBreadcrumbResponse = yield call(getBreadcrumbCall);
    const { data } = getBreadcrumbResponse;
    switch (data?.breadcrumbs.__typename) {
      case "BreadcrumbsResponse":
        yield put(
          getBreadcrumbSuccessAction(
            data?.breadcrumbs as Required<BreadcrumbsResponse>,
          ),
        );
        break;
    }
  } catch (error) {
    yield put(
      getBreadcrumbFailAction(error?.message || "Failed to get breadcrumb"),
    );
    // appInsights.trackException(error);
  }
}

export default function* breadcrumbSagas() {
  yield takeLatest("breadCrumb/getBreadcrumbAction", getBreadcrumbSaga);
}
