import { call, put, takeEvery, all } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { gql } from "graphql-tag";
import {
  BookingSummary_S,
  BookingSummaryResponseUnion,
  MutationPopulateAbandonedBasketBookingArgs,
} from "@generated/types";
import { loadCustomGraphQLMutation } from "src/utils";
import {
  abandonedBasket,
  abandonedBasketFail,
  abandonedBasketSuccess,
  addCabinSuccess,
} from "../slices";

function* createAbandonedBasketSaga(
  action: ReturnType<typeof abandonedBasket>,
) {
  const dynamicAbandonedBasketMutation = yield call(
    loadCustomGraphQLMutation,
    "populateAbandonedBasketBooking",
  );
  const abandonedBasketCall = () =>
    apolloClient.mutate<
      { populateAbandonedBasketBooking: BookingSummaryResponseUnion },
      MutationPopulateAbandonedBasketBookingArgs
    >({
      mutation: gql`
        ${dynamicAbandonedBasketMutation}
      `,
      variables: action.payload,
    });

  try {
    const response = yield call(abandonedBasketCall);
    const data = response.data?.populateAbandonedBasketBooking;
    switch (data?.__typename) {
      case "BookingSummaryResponse": {
        yield put(
          abandonedBasketSuccess(data?.bookingSummary as BookingSummary_S),
        );
        yield put(addCabinSuccess(data?.bookingSummary as BookingSummary_S));
        break;
      }
      case "SystemError": {
        yield put(abandonedBasketFail(data?.message || ""));
        break;
      }
      default:
        break;
    }
  } catch (error: any) {
    yield put(abandonedBasketFail(error.message));
    // appInsights.trackException(error);
  }
}

export function* abandonedBasketSagas() {
  yield all([
    takeEvery("abandonedBasket/abandonedBasket", createAbandonedBasketSaga),
  ]);
}
