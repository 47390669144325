import { createSlice } from "@reduxjs/toolkit";
import {
  CabinPanelBedroomOption_S as CabinPanelBedroomOption,
  CabinPanelSummary_S as CabinPanelSummary,
} from "@generated/types";
import { ReservationPrice } from "../../interfaces/reservation";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type Extras = {
  [name: string]: {
    extraId: string;
    extraOptionId: string;
    extraOptionName: string;
    extraOptionPrice: number;
    quantity?: number;
    totalPrice: number;
    sellingPrice: number;
  };
};

export type ReservationState = {
  cabinId?: string;
  petFriendly?: boolean;
  price: ReservationPrice;
  priceCheck: string;
  priceExpiry?: string;
  numberOfBedrooms?: number;
  isDda?: boolean;
  cabinTypeName?: string;
  extras: Nullable<Extras>;
  promoCode?: string;
};

const initialState: ReservationState = Object.freeze({
  priceCheck: "",
  price: {
    price: 0,
    discountPrice: 0,
    standardPrice: 0,
    tacticalPrice: 0,
    channelPrice: 0,
    cabinSellingPrice: 0,
  },
  extras: null,
});
export type BookingCabinBedroom = {
  cabin: CabinPanelSummary;
  cabinBedroom: CabinPanelBedroomOption;
  experiments?: { [key in string]: string | boolean };
};
export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setCabinId: (state, action: PayloadAction<string>) => {
      state.cabinId = action.payload;
    },
    setPetFriendly: (state, action: PayloadAction<boolean>) => {
      state.petFriendly = action.payload;
    },
    setPrice: (state, action: PayloadAction<ReservationPrice>) => {
      state.price = action.payload;
    },
    setPriceCheck: (state, action: PayloadAction<string>) => {
      state.priceCheck = action.payload;
    },
    setPriceExpiry: (state, action: PayloadAction<string>) => {
      state.priceExpiry = action.payload;
    },
    setNumberOfBedrooms: (state, action: PayloadAction<number>) => {
      state.numberOfBedrooms = action.payload;
    },
    setIsCabinDda: (state, action: PayloadAction<boolean>) => {
      state.isDda = action.payload;
    },
    setCabinTypeName: (state, action: PayloadAction<string>) => {
      state.cabinTypeName = action.payload;
    },
    resetReservation: () => ({
      ...initialState,
    }),
    setBookingBedroom: (
      state,
      action: PayloadAction<BookingCabinBedroom>,
    ) => {},
  },
});

export const {
  setCabinId,
  setPetFriendly,
  setPrice,
  setPriceCheck,
  setPriceExpiry,
  setNumberOfBedrooms,
  setIsCabinDda,
  setCabinTypeName,
  resetReservation,
  setBookingBedroom,
} = reservationSlice.actions;

export default reservationSlice.reducer;
