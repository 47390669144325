import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  AddGiftVoucherToBasketResponse,
  AddGiftVoucherToBasketResponseUnion,
  ConfirmedGiftVouchersResponseUnion,
  EditGiftVoucherResponse,
  EditGiftVoucherResponseUnion,
  GiftVoucherBasketResponse,
  GiftVoucherSettingsResponse,
  MutationAddGiftVoucherToBasketArgs,
  MutationEditGiftVoucherArgs,
  MutationMakePaymentForGiftVouchersArgs,
  MutationRemoveGiftVoucherFromBasketArgs,
  QueryConfirmedGiftVoucherItemsArgs,
  QueryGiftVoucherBasketArgs,
  RemoveGiftVoucherFromBasketResponse,
  RemoveGiftVoucherFromBasketResponseUnion,
} from "@generated/types";
import {
  gvBasketIdPersistedStateSelector,
  errorMessagesDataSelector,
  loginSelector,
} from "../selectors";
import {
  addGiftVoucher,
  addGiftVoucherFail,
  addGiftVoucherSuccess,
  getGiftVoucherBasket,
  getGiftVoucherBasketFail,
  getGiftVoucherBasketSuccess,
  confirmedGiftVoucher,
  confirmedGiftVoucherFail,
  confirmedGiftVoucherSuccess,
  editGiftVoucherFail,
  editGiftVoucherSuccess,
  giftVoucherPayment,
  giftVoucherPaymentFail,
  giftVoucherPaymentSuccess,
  removeGiftVoucher,
  removeGiftVoucherSuccess,
  setGvBasketId,
  getGvSettingsFailAction,
  getGvSettingsSuccessAction,
} from "../slices";
import { loadGraphQLQuery, loadGraphQLMutation } from "../../utils";
import { getErrorMessageByKey } from "../../components/Utils";

function* getGiftVoucherBasketSaga(
  action: ReturnType<typeof getGiftVoucherBasket>,
) {
  const dynamicGiftVoucherBasket = yield call(
    loadGraphQLQuery,
    "giftVoucherBasket",
  );
  const getGiftVoucherBasketCall = () =>
    apolloClient.query<
      { giftVoucherBasket: GiftVoucherBasketResponse },
      QueryGiftVoucherBasketArgs
    >({
      query: gql`
        ${dynamicGiftVoucherBasket}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getGiftVoucherBasketCall);
    if (response.data?.giftVoucherBasket) {
      yield put(getGiftVoucherBasketSuccess(response.data.giftVoucherBasket));
    } else {
      throw Error("Failed to get gift voucher basket");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getGiftVoucherBasketFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* addGiftVoucherSaga(action: ReturnType<typeof addGiftVoucher>) {
  const dynamicAddGiftVoucher = yield call(
    loadGraphQLMutation,
    "addGiftVoucherToBasket",
  );
  const { data } = yield select(loginSelector);
  const addGiftVoucherCall = () =>
    apolloClient.mutate<
      { addGiftVoucherToBasket: AddGiftVoucherToBasketResponseUnion },
      MutationAddGiftVoucherToBasketArgs
    >({
      mutation: gql`
        ${dynamicAddGiftVoucher}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
    });

  try {
    const response = yield call(addGiftVoucherCall);
    switch (response.data?.addGiftVoucherToBasket?.__typename) {
      case "AddGiftVoucherToBasketResponse": {
        yield put(
          setGvBasketId(
            response?.data?.addGiftVoucherToBasket?.giftVoucher?.basketId?.toString() as string,
          ),
        );
        yield put(addGiftVoucherSuccess(response.data.addGiftVoucherToBasket));
        break;
      }
      case "AddGiftVoucherToBasketError":
        const errorMessages = yield select(errorMessagesDataSelector);
        const errorByKey = getErrorMessageByKey(
          response?.data?.addGiftVoucherToBasket.message || "",
          errorMessages,
        );

        yield put(
          addGiftVoucherFail(
            errorByKey?.message || "Could not add gift voucher to basket",
          ),
        );
        break;
      default:
        break;
    }
  } catch (error: any) {
    yield put(addGiftVoucherFail(error.message));
    // appInsights.trackException(error);
  }
}

function* editGiftVoucherSaga(
  action: ReturnType<typeof editGiftVoucherSuccess>,
) {
  const dynamicEditGiftVoucher = yield call(
    loadGraphQLMutation,
    "editGiftVoucher",
  );
  const editGiftVoucherCall = () =>
    apolloClient.mutate<
      { editGiftVoucher: EditGiftVoucherResponseUnion },
      MutationEditGiftVoucherArgs
    >({
      mutation: gql`
        ${dynamicEditGiftVoucher}
      `,
      variables: action.payload,
    });

  try {
    const response = yield call(editGiftVoucherCall);
    switch (response.data?.editGiftVoucher?.__typename) {
      case "EditGiftVoucherResponse": {
        yield put(editGiftVoucherSuccess(response.data.editGiftVoucher));
        break;
      }
      default:
        break;
    }
  } catch (error: any) {
    yield put(editGiftVoucherFail(error.message));
    // appInsights.trackException(error);
  }
}

function* getGvSettingsSaga(
  action: ReturnType<typeof getGvSettingsSuccessAction>,
) {
  const dynamicGiftVoucherSettings = yield call(
    loadGraphQLQuery,
    "giftVoucherSettings",
  );
  const getGvSettingsCall = () =>
    apolloClient.query<{
      giftVoucherSettings: GiftVoucherSettingsResponse;
    }>({
      query: gql`
        ${dynamicGiftVoucherSettings}
      `,
      fetchPolicy: "no-cache",
    });

  try {
    const getGvSettingsResponse = yield call(getGvSettingsCall);
    const gvSettings = getGvSettingsResponse.data?.giftVoucherSettings;
    if (!!gvSettings) {
      yield put(
        getGvSettingsSuccessAction(
          gvSettings as Required<GiftVoucherSettingsResponse>,
        ),
      );
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);

    yield put(getGvSettingsFailAction(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* removeGiftVoucherSaga(action: ReturnType<typeof removeGiftVoucher>) {
  const dynamicRemoveGiftVoucher = yield call(
    loadGraphQLMutation,
    "removeGiftVoucherFromBasket",
  );
  const removeGiftVoucherCall = () =>
    apolloClient.mutate<
      {
        removeGiftVoucherFromBasket: RemoveGiftVoucherFromBasketResponseUnion;
      },
      MutationRemoveGiftVoucherFromBasketArgs
    >({
      mutation: gql`
        ${dynamicRemoveGiftVoucher}
      `,
      variables: {
        basketItemId: action.payload,
      },
    });

  try {
    const response = yield call(removeGiftVoucherCall);
    const { removeGiftVoucherFromBasket } = response.data!;
    switch (removeGiftVoucherFromBasket?.__typename) {
      case "RemoveGiftVoucherFromBasketResponse": {
        yield put(removeGiftVoucherSuccess(removeGiftVoucherFromBasket));
        break;
      }
      case "SystemError": {
        throw Error("Failed to remove gift voucher");
      }
      default:
        break;
    }
  } catch (error: any) {
    yield put(
      getGvSettingsFailAction(
        error?.message || "Failed to get Gift Voucher settings",
      ),
    );
    // appInsights.trackException(error);
  }
}

function* makeGVCardPaymentSaga(action: ReturnType<typeof giftVoucherPayment>) {
  const dynamicMakePaymentForGiftVoucher = yield call(
    loadGraphQLMutation,
    "makePaymentForGiftVouchers",
  );
  const createPaymentCall = () =>
    apolloClient.mutate<
      { makePaymentForGiftVouchers: PaymentResponse },
      MutationMakePaymentForGiftVouchersArgs
    >({
      mutation: gql`
        ${dynamicMakePaymentForGiftVoucher}
      `,
      variables: {
        paymentRequest: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(createPaymentCall);
    const { makePaymentForGiftVouchers } = response.data!;

    if (
      makePaymentForGiftVouchers?.success &&
      (makePaymentForGiftVouchers?.status === "OK" ||
        makePaymentForGiftVouchers?.status === "3DAUTH" ||
        makePaymentForGiftVouchers?.status === "GVOK")
    ) {
      yield put(giftVoucherPaymentSuccess(makePaymentForGiftVouchers));
    } else {
      throw Error(
        makePaymentForGiftVouchers?.statusDetail ||
          makePaymentForGiftVouchers?.nonValidReason ||
          "Failed to add vouchers.",
      );
    }
  } catch (error: any) {
    yield put(giftVoucherPaymentFail(error.message));
    // appInsights.trackException(error);
  }
}

function* getConfirmedGiftVoucherSaga(
  action: ReturnType<typeof confirmedGiftVoucher>,
) {
  const dynamicConfimedGiftVoucherItems = yield call(
    loadGraphQLQuery,
    "confirmedGiftVoucherItems",
  );
  const getGiftVoucherBasketCall = () =>
    apolloClient.query<
      { confirmedGiftVoucherItems: ConfirmedGiftVouchersResponseUnion },
      QueryConfirmedGiftVoucherItemsArgs
    >({
      query: gql`
        ${dynamicConfimedGiftVoucherItems}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(getGiftVoucherBasketCall);
    const data = response.data?.confirmedGiftVoucherItems;
    switch (data?.__typename) {
      case "ConfirmedGiftVouchersResponse": {
        yield put(confirmedGiftVoucherSuccess(data));
        break;
      }
      case "SystemError": {
        throw Error(data?.message || "");
      }
      default: {
        throw Error("Failed to get confimed gift vouchers.");
      }
    }
  } catch (error: any) {
    yield put(confirmedGiftVoucherFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* reFetchGiftVoucherSaga(
  action:
    | ReturnType<typeof addGiftVoucherSuccess>
    | ReturnType<typeof removeGiftVoucher>
    | ReturnType<typeof editGiftVoucherSuccess>,
) {
  const { basketId } = yield select(gvBasketIdPersistedStateSelector);
  try {
    yield put(
      getGiftVoucherBasket({
        basketId: Number(basketId || 0),
      }),
    );
  } catch (e) {
    console.log(e, "error");
  }
}

export default function* gvSettingsSagas() {
  yield all([
    takeLatest("gvSettings/getGvSettingsAction", getGvSettingsSaga),
    takeLatest(
      "giftVoucherBasket/getGiftVoucherBasket",
      getGiftVoucherBasketSaga,
    ),
    takeEvery("giftVoucherAdd/addGiftVoucher", addGiftVoucherSaga),
    takeEvery("giftVouchersSaved/editGiftVoucher", editGiftVoucherSaga),
    takeEvery("giftVoucherRemove/removeGiftVoucher", removeGiftVoucherSaga),
    takeLatest("giftVoucherAdd/addGiftVoucherSuccess", reFetchGiftVoucherSaga),
    takeLatest(
      "giftVoucherRemove/removeGiftVoucherSuccess",
      reFetchGiftVoucherSaga,
    ),
    takeLatest(
      "giftVouchersSaved/editGiftVoucherSuccess",
      reFetchGiftVoucherSaga,
    ),
    takeEvery("giftVouchersPayment/giftVoucherPayment", makeGVCardPaymentSaga),
    takeEvery(
      "giftVoucherConfirmedBasket/confirmedGiftVoucher",
      getConfirmedGiftVoucherSaga,
    ),
  ]);
}
