import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  ConfirmExtrasResponseUnion,
  MutationConfirmFreeExtrasArgs,
} from "@generated/types";
import {
  loginSelector,
  bookingPersistedStateSelector,
  extraListSelector,
  voucherStateSelector,
} from "../selectors";
import {
  createConfirmFreeExtras,
  createConfirmFreeExtrasFail,
  createConfirmFreeExtrasSuccess,
  getBookingConfirmationAction,
  getFutureBookingsFail,
  setExtras,
} from "../slices";
import { loadGraphQLMutation } from "src/utils";

function* createConfirmFreeExtrasSaga(
  action: ReturnType<typeof createConfirmFreeExtras>,
) {
  const dynamicConfirmFreeExtras = yield call(
    loadGraphQLMutation,
    "confirmFreeExtras",
  );
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const createConfirmFreeCall = () =>
      apolloClient.mutate<
        { confirmFreeExtras: ConfirmExtrasResponseUnion },
        MutationConfirmFreeExtrasArgs
      >({
        mutation: gql`
          ${dynamicConfirmFreeExtras}
        `,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });
    try {
      const response = yield call(createConfirmFreeCall);
      const data = response.data?.confirmFreeExtras;
      const { bookingId } = yield select(bookingPersistedStateSelector);
      const { list } = yield select(extraListSelector);
      const { totalVoucherDiscount } = yield select(voucherStateSelector);
      const basketExtras = list?.data?.basketExtras;
      switch (data?.__typename) {
        case "ConfirmExtrasResponse": {
          yield put(createConfirmFreeExtrasSuccess(data));
          yield all([
            put(
              getBookingConfirmationAction({
                bookingId,
              }),
            ),
            put(
              setExtras({
                purchasedExtras: basketExtras,
                purchasedExtrasTotalPrice: list?.data?.obtainingQuoteTotal ?? 0,
                vouchersTotalPrice: totalVoucherDiscount,
              }),
            ),
          ]);
          break;
        }
        case "SystemError": {
          yield put(
            createConfirmFreeExtrasFail(
              data?.message || "Failed to confirm free extras.",
            ),
          );
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(createConfirmFreeExtrasFail(error.message));
    }
  } else {
    const error: any =
      "Unable to get Single Future Cabin Reservation due to missing token.";
    yield put(getFutureBookingsFail(error));
  }
}

export function* confirmFreeExtrasSagas() {
  yield all([
    takeEvery(
      "confirmFreeExtras/createConfirmFreeExtras",
      createConfirmFreeExtrasSaga,
    ),
  ]);
}
