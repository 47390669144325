import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  BookableLocation,
  LocationAvailabilityResponse,
  LocationBlockInfoResponse,
  PlanYourVisitResponseUnion,
  QueryBookableLocationsArgs,
  QueryLocationAvailabilityArgs,
  QueryPlanYourVisitArgs,
} from "@generated/types";

import {
  getLocationsSuccess,
  getLocationsFail,
  LocationState,
  getLocationsAction,
  selectAllLocations,
  selectLocation,
  getPlanYourVisitSuccess,
  getPlanYourVisitFail,
  getPlanYourVisit,
  getLocationBlockActionSuccess,
  getLocationBlockAction,
  getLocationBlockActionFail,
  getAvailableLocations,
  getAvailableLocationsFail,
  getAvailableLocationsSuccess,
  setLocationSearchFilter,
  setSelectedLocationIds,
} from "../slices";
import {
  locationFilterSelector,
  locationSortSelector,
  locationsSelector,
  bookingSearchSelector,
} from "../selectors";
import moment from "moment";
import { loadGraphQLQuery, loadCustomGraphQLQuery } from "../../utils";

function* getLocationsSaga(action: ReturnType<typeof getLocationsAction>) {
  const dynamicLocations = yield call(loadGraphQLQuery, "bookableLocations");
  const locationsCall = () =>
    apolloClient.query<
      { bookableLocations: BookableLocation[] },
      QueryBookableLocationsArgs
    >({
      query: gql`
        ${dynamicLocations}
      `,
      variables: {
        request: action.payload,
      },
      context: {
        timeout: 120000, // it's a temparary timeout fix and will be removed after the bsl fix.
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(locationsCall);
    const { bookableLocations } = response.data!;

    if (bookableLocations) {
      yield put(getLocationsSuccess(bookableLocations));
    } else {
      throw Error("Failed to get locations");
    }
  } catch (error) {
    yield put(getLocationsFail(error.message));
  }
}

function* getPlanYourVisitSaga(action: ReturnType<typeof getPlanYourVisit>) {
  const dynamicPlanYourVisit = yield call(loadGraphQLQuery, "planYourVisit");
  const planYourVisitCall = () =>
    apolloClient.query<
      { planYourVisit: PlanYourVisitResponseUnion },
      QueryPlanYourVisitArgs
    >({
      query: gql`
        ${dynamicPlanYourVisit}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(planYourVisitCall);
    const { data } = response;

    switch (data?.planYourVisit.__typename) {
      case "PlanYourVisitResponse": {
        yield put(getPlanYourVisitSuccess(data.planYourVisit));
        break;
      }
      case "SystemError": {
        throw Error(data?.planYourVisit.message || "");
      }
      default: {
        throw Error("Failed to get Plan Your Visit");
      }
    }
  } catch (error) {
    yield put(getPlanYourVisitFail(error.message));
  }
}

function* locationInfoBlockSaga(
  action: ReturnType<typeof getLocationBlockAction>,
) {
  const dynamicLocationBlockInfo = yield call(
    loadGraphQLQuery,
    "locationBlockInfo",
  );
  const locationsInfoBlockCall = () =>
    apolloClient.query<LocationBlockInfoResponse>({
      query: gql`
        ${dynamicLocationBlockInfo}
      `,
    });

  try {
    const response = yield call(locationsInfoBlockCall);
    const { locationBlockInfo } = response.data! as any; // TODO: to be investigated as data doesn't match with type

    if (locationBlockInfo) {
      yield put(getLocationBlockActionSuccess(locationBlockInfo));
    } else {
      throw Error("Failed to get locations");
    }
  } catch (error) {
    yield put(getLocationBlockActionFail(error.message));
  }
}

function* locationSearchFilteredSaga(
  action: ReturnType<typeof setLocationSearchFilter>,
) {
  try {
    const {
      dda,
      guests,
      selectedLocationIds: selectedLocations,
      promoCode,
      dateRange,
    } = yield select(bookingSearchSelector);
    const locationSort =
      action?.payload && !!action.payload.sortMethod
        ? action.payload.sortMethod
        : yield select(locationSortSelector);
    const locationFilters =
      action?.payload && !!action.payload.filters
        ? action.payload.filters
        : yield select(locationFilterSelector);

    const startDate = moment(dateRange.startDateISO);
    const endDate = moment(dateRange.endDateISO);

    yield put(
      getAvailableLocations({
        locationsQuery: {
          request: {
            cabinTypeIds: [],
            bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
            disabledAccess: dda,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : endDate,
            locationIds: selectedLocations,
            numberOfAdults: guests.adults,
            numberOfBedrooms: guests.bedrooms,
            numberOfChildren: guests.children,
            numberOfPets: guests.pets,
            numberOfInfants: guests.infants,
            startDate:
              startDate !== null ? startDate.format("YYYY-MM-DD") : startDate,
            sortMethod: locationSort,
            promoCode,
          },
          selectedTagIds:
            !!locationFilters && !!locationFilters.length
              ? locationFilters
              : undefined,
        },
        filtered: true,
      }),
    );
  } catch (e) {
    console.log(e, "exception.....");
  }
}

function* selectLocationSaga(action: ReturnType<typeof selectLocation>) {
  const { selectedLocationIds } = action.payload;
  yield put(setSelectedLocationIds(selectedLocationIds));
}

function* selectLocationsSaga(action: ReturnType<typeof selectAllLocations>) {
  const locationsState: LocationState = yield select(locationsSelector);
  const { bookableLocations } = locationsState;
  const { checked } = action.payload;
  const locationIds = checked
    ? (bookableLocations || [])?.map((location) => location.id).sort()
    : [];
  yield put(setSelectedLocationIds(locationIds));
}

function* getAvailableLocationsSaga(
  action: ReturnType<typeof getAvailableLocations>,
) {
  const dynamicAvailableLocations = yield call(
    loadCustomGraphQLQuery,
    "locationAvailability",
  );
  const availableLocationsCall = () =>
    apolloClient.query<
      { locationAvailability: LocationAvailabilityResponse },
      QueryLocationAvailabilityArgs
    >({
      query: gql`
        ${dynamicAvailableLocations}
      `,
      variables: action.payload.locationsQuery,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(availableLocationsCall);
    const { locationAvailability } = response.data!;

    if (locationAvailability) {
      yield put(
        getAvailableLocationsSuccess({
          summaryResponse: locationAvailability,
          filtered: action.payload.filtered,
        }),
      );
    } else {
      throw Error("Failed to get available locations");
    }
  } catch (error) {
    yield put(getAvailableLocationsFail(error.message));
  }
}

export default function* locationSagas() {
  yield all([
    takeLatest("locations/getLocationsAction", getLocationsSaga),
    takeLatest("locations/selectLocation", selectLocationSaga),
    takeLatest("locations/selectAllLocations", selectLocationsSaga),
    takeLatest(
      "locationSearch/getAvailableLocations",
      getAvailableLocationsSaga,
    ),
    takeLatest("planYourVisit/getPlanYourVisit", getPlanYourVisitSaga),
    takeLatest(
      "locationBlockInfo/getLocationBlockAction",
      locationInfoBlockSaga,
    ),
    takeLatest(
      "locationSearch/setLocationSearchFilter",
      locationSearchFilteredSaga,
    ),
  ]);
}
