import { all, call, put, takeEvery } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";

import {
  ChangeCustomerPasswordResponseUnion,
  MutationChangeCustomerPasswordArgs,
} from "@generated/types";
import {
  createChangePassword,
  createChangePasswordFail,
  createChangePasswordSuccess,
  createLogoutFromSession,
} from "../slices";
import { loadGraphQLMutation } from "src/utils";

function* createChangePasswordSaga(
  action: ReturnType<typeof createChangePassword>,
) {
  const dynamicCreateChangePassword = yield call(
    loadGraphQLMutation,
    "changeCustomerPassword",
  );
  const createChangePasswordCall = () =>
    apolloClient.mutate<
      { changeCustomerPassword: ChangeCustomerPasswordResponseUnion },
      MutationChangeCustomerPasswordArgs
    >({
      mutation: gql`
        ${dynamicCreateChangePassword}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(createChangePasswordCall);
    const data = response.data?.changeCustomerPassword;
    switch (data?.__typename) {
      case "ChangeCustomerPasswordResponse": {
        yield put(createChangePasswordSuccess(data));
        if (data?.success) {
          yield put(createLogoutFromSession());
        }
        break;
      }
      case "ChangeCustomerPasswordError": {
        yield put(createChangePasswordFail(data?.message));
        break;
      }
      default:
        break;
    }
  } catch (error: any) {
    yield put(createChangePasswordFail(error.message));
  }
}

export default function* changePasswordSagas() {
  yield all([
    takeEvery(
      "changeCustomerPassword/createChangePassword",
      createChangePasswordSaga,
    ),
  ]);
}
