import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import { Voucher } from "../../components/SemanticTheme/VoucherForm";
import {
  ApplyGiftVoucherResponse,
  GiftVoucherIsValidResponse,
  MutationApplyGiftVoucherToBookingArgs,
  MutationRemoveGiftVoucherFromBookingArgs,
  QueryGiftVoucherIsValidArgs,
  RemoveGiftVoucherResponse,
} from "@generated/types";
import { voucherStateSelector } from "../selectors";
import {
  applyVoucher,
  applyVoucherFail,
  applyVoucherSuccess,
  removeVoucherFail,
  removeVoucherSuccessAction,
  addVoucher,
  checkVoucher,
  checkVoucherFail,
  checkVoucherSuccess,
  removeVoucher,
  removeVoucherSuccess,
  setTotalVoucherDiscount,
  setTotalVoucherValueLost,
  getBookingConfirmationAction,
} from "../slices";
import { loadGraphQLMutation, loadGraphQLQuery } from "src/utils";

function* checkVoucherSaga(action: ReturnType<typeof checkVoucher>) {
  const dynamicCheckVoucher = yield call(
    loadGraphQLQuery,
    "giftVoucherIsValid",
  );
  const checkVoucherCall = () =>
    apolloClient.query<
      { giftVoucherIsValid: GiftVoucherIsValidResponse },
      QueryGiftVoucherIsValidArgs
    >({
      query: gql`
        ${dynamicCheckVoucher}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(checkVoucherCall);
    const giftVoucherIsValid = response.data?.giftVoucherIsValid;
    const { bookingId, giftVoucherCode } = action.payload;

    if (giftVoucherIsValid?.valid) {
      yield put(checkVoucherSuccess(giftVoucherIsValid));
      yield put(
        applyVoucher({
          bookingId,
          giftVoucherCode,
        }),
      );
    } else {
      throw Error(giftVoucherIsValid?.reason || undefined);
    }
  } catch (error) {
    yield put(checkVoucherFail(error.message));
  }
}

function* applyVoucherSaga(action: ReturnType<typeof applyVoucher>) {
  const dynamicApplyVoucher = yield call(
    loadGraphQLMutation,
    "applyGiftVoucherToBooking",
  );
  const applyVoucherCall = () =>
    apolloClient.mutate<
      { applyGiftVoucherToBooking: ApplyGiftVoucherResponse },
      MutationApplyGiftVoucherToBookingArgs
    >({
      mutation: gql`
        ${dynamicApplyVoucher}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(applyVoucherCall);
    const applyGiftVoucherToBooking = response.data?.applyGiftVoucherToBooking;

    if (applyGiftVoucherToBooking?.success) {
      yield put(
        applyVoucherSuccess({
          response: applyGiftVoucherToBooking,
          bookingId: action.payload.bookingId,
        }),
      );
    } else {
      throw Error(
        applyGiftVoucherToBooking?.reason || "Failed to apply voucher",
      );
    }
  } catch (error) {
    yield put(applyVoucherFail(error.message));
  }
}

function* addVoucherSaga(action: ReturnType<typeof applyVoucherSuccess>) {
  const { currentVoucherCode, data } = yield select(voucherStateSelector);

  if (currentVoucherCode) {
    yield put(
      addVoucher({
        code: currentVoucherCode || "",
        value: data?.giftVoucherValue,
        valueLost: data?.giftVoucherValueLost,
      }),
    );
  }
}

function* getBookingConfirmationSaga(
  action: ReturnType<typeof applyVoucherSuccess | typeof removeVoucherSuccess>,
) {
  const bookingId = action.payload.bookingId;
  if (bookingId) {
    yield put(
      getBookingConfirmationAction({
        bookingId,
      }),
    );
  }
}

function* removeVoucherSaga(action: ReturnType<typeof removeVoucher>) {
  const dynamicRemoveVoucher = yield call(
    loadGraphQLMutation,
    "removeGiftVoucherFromBooking",
  );
  const removeVoucherCall = () =>
    apolloClient.mutate<
      { removeGiftVoucherFromBooking: RemoveGiftVoucherResponse },
      MutationRemoveGiftVoucherFromBookingArgs
    >({
      mutation: gql`
        ${dynamicRemoveVoucher}
      `,
      variables: action.payload.voucherCode,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(removeVoucherCall);
    const removeGiftVoucherToBooking =
      response.data?.removeGiftVoucherFromBooking;

    if (removeGiftVoucherToBooking?.success) {
      yield put(removeVoucherSuccess({ bookingId: action.payload.bookingId }));
      yield put(removeVoucherSuccessAction(removeGiftVoucherToBooking));
    } else {
      throw Error(
        removeGiftVoucherToBooking?.reason || "Failed to remove voucher",
      );
    }
  } catch (error) {
    yield put(removeVoucherFail(error.message));
  }
}

function* setVoucherTotalDiscountSaga(
  action: ReturnType<
    typeof setTotalVoucherDiscount | typeof setTotalVoucherValueLost
  >,
) {
  const { vouchers } = yield select(voucherStateSelector);
  const discount = vouchers.reduce((a: number, b: Voucher) => a + b.value, 0);
  const valueLost = vouchers.reduce(
    (a: number, b: Voucher) => a + b.valueLost,
    0,
  );

  yield put(setTotalVoucherDiscount(discount));
  yield put(setTotalVoucherValueLost(valueLost));
}

export function* voucherSagas() {
  yield all([
    takeLatest("voucher/checkVoucher", checkVoucherSaga),
    takeLatest("voucher/checkVoucherSuccess", addVoucherSaga),
    takeEvery("voucherApply/applyVoucher", applyVoucherSaga),
    takeEvery("voucherApply/applyVoucherSuccess", getBookingConfirmationSaga),
    takeEvery("voucher/removeVoucherSuccess", getBookingConfirmationSaga),
    takeEvery("voucher/addVoucher", setVoucherTotalDiscountSaga),
    takeLatest("voucher/removeVoucherSuccess", setVoucherTotalDiscountSaga),
    takeEvery("voucher/removeVoucher", removeVoucherSaga),
  ]);
}
