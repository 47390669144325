import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  AddUpdateVehicleResponseUnion,
  DeleteVehicleResponseUnion,
  MutationAddUpdateVehicleArgs,
  MutationDeleteVehicleArgs,
} from "@generated/types";
import { loadCustomGraphQLMutation } from "src/utils";
import { loginSelector } from "../selectors";
import {
  addVehicleSuccess,
  addVehicleFail,
  addVehicle,
  removeVehicleSuccess,
  removeVehicleFail,
  removeVehicle,
} from "../slices";

function* addVehicleSaga(action: ReturnType<typeof addVehicle>) {
  const dynamicAddVehicle = yield call(
    loadCustomGraphQLMutation,
    "addUpdateVehicle",
  );
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const addVehicleCall = () =>
      apolloClient.mutate<
        { addUpdateVehicle: AddUpdateVehicleResponseUnion },
        MutationAddUpdateVehicleArgs
      >({
        mutation: gql`
          ${dynamicAddVehicle}
        `,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      });

    try {
      const response = yield call(addVehicleCall);
      const { addUpdateVehicle } = response.data!;

      switch (addUpdateVehicle?.__typename) {
        case "Vehicle": {
          yield put(addVehicleSuccess(addUpdateVehicle));
          break;
        }
        case "AddUpdateVehicleError": {
          throw Error(addUpdateVehicle?.message || "Failed to add vehicle");
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(addVehicleFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to add or Update Vehicle due to missing token.";
    yield put(addVehicleFail(error));
    // appInsights.trackException(error);
  }
}

function* removeVehicleSaga(action: ReturnType<typeof removeVehicle>) {
  const dynamicDeleteVehicle = yield call(
    loadCustomGraphQLMutation,
    "deleteVehicle",
  );
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const removeVehicleCall = () =>
      apolloClient.mutate<
        { deleteVehicle: DeleteVehicleResponseUnion },
        MutationDeleteVehicleArgs
      >({
        mutation: gql`
          ${dynamicDeleteVehicle}
        `,
        variables: {
          vehicle: action.payload,
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      });

    try {
      const response = yield call(removeVehicleCall);
      const { deleteVehicle } = response.data!;

      switch (deleteVehicle?.__typename) {
        case "DeleteVehicleResponse": {
          yield put(removeVehicleSuccess(deleteVehicle));
          break;
        }
        case "DeleteVehicleError": {
          throw Error(deleteVehicle?.message || "Failed to remove vehicle");
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(removeVehicleFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to RemoveVehicle due to missing token.";
    yield put(removeVehicleFail(error));
    // appInsights.trackException(error);
  }
}

export default function* vehicleSagas() {
  yield all([
    takeEvery("vehicleAdd/addVehicle", addVehicleSaga),
    takeEvery("vehicleRemove/removeVehicle", removeVehicleSaga),
  ]);
}
