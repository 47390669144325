import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import { getErrorMessageByKey } from "../../components/Utils";
import {
  QueryScheduledPaymentsByBookingIdArgs,
  ScheduledPaymentsByBookingIdResponse,
} from "@generated/types";
import { errorMessagesDataSelector } from "../selectors";
import {
  getScheduledPaymentsByBookingIdAction,
  getScheduledPaymentsByBookingIdFailAction,
  getScheduledPaymentsByBookingIdSuccessAction,
} from "../slices";
import { loadCustomGraphQLQuery } from "../../utils";

function* getScheduledPaymentsByBookingId(
  action: ReturnType<typeof getScheduledPaymentsByBookingIdAction>,
) {
  const dynamicScheduledPaymentByBookingId = yield call(
    loadCustomGraphQLQuery,
    "scheduledPaymentsByBookingId",
  );
  const getScheduledPaymentsByBookingIdCall = () =>
    apolloClient.query<
      { scheduledPaymentsByBookingId: ScheduledPaymentsByBookingIdResponse },
      QueryScheduledPaymentsByBookingIdArgs
    >({
      query: gql`
        ${dynamicScheduledPaymentByBookingId}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getScheduledPaymentsByBookingIdCall);
    if (response.data?.scheduledPaymentsByBookingId) {
      yield put(
        getScheduledPaymentsByBookingIdSuccessAction(
          response.data.scheduledPaymentsByBookingId,
        ),
      );
    } else {
      throw Error("Failed to get scheduled payments by booking id");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(
      getScheduledPaymentsByBookingIdFailAction(
        errorByKey?.message || error.message,
      ),
    );
    // appInsights.trackException(error);
  }
}

export default function* scheduledPaymentsByBookingIdSagas() {
  yield all([
    takeLatest(
      "scheduledPaymentsByBookingId/getScheduledPaymentsByBookingIdAction",
      getScheduledPaymentsByBookingId,
    ),
  ]);
}
