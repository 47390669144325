import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { StepperTag } from "../../interfaces/booking";
import { apolloClient } from "../../apollo-client";
import {
  ClearUnconfirmedExtrasResponse,
  MutationClearUnconfirmedExtrasArgs,
} from "@generated/types";
import { fhBookingStepsSelector } from "../selectors";
import { removeExtraSticky, removeSticky } from "../slices";
import { getBookingStepByTag, loadGraphQLMutation } from "../../utils";
import { useNextRouterPathname } from "../../utils/common";
import { useRouter } from "next/router";

function* resetSticky() {
  const fhBookingSteps = yield select(fhBookingStepsSelector);
  const cabinStep = getBookingStepByTag(StepperTag.cabins, fhBookingSteps);
  const locationStep = getBookingStepByTag(
    StepperTag.locations,
    fhBookingSteps,
  );
  const history = useRouter();
  const url = useNextRouterPathname(history);
  const isOnASearchPage =
    url &&
    ((cabinStep?.url || "") === url || (locationStep?.url || "") === url);
  if (!isOnASearchPage) {
    yield put(removeSticky());
  }
}

function* checkForBookingConfirmationSaga() {
  const fhBookingSteps = yield select(fhBookingStepsSelector);

  const paymentStep = getBookingStepByTag(StepperTag.payment, fhBookingSteps);
  if (paymentStep?.completed) {
    yield put(removeSticky());
  }
}

function* cancelExtraBasketSaga(action: ReturnType<typeof removeExtraSticky>) {
  const dynamicClearUnconfirmedExtras = yield call(
    loadGraphQLMutation,
    "clearUnconfirmedExtras",
  );
  const clearUnconfirmedExtrasCall = () =>
    apolloClient.mutate<
      { clearUnconfirmedExtras: ClearUnconfirmedExtrasResponse },
      MutationClearUnconfirmedExtrasArgs
    >({
      mutation: gql`
        ${dynamicClearUnconfirmedExtras}
      `,
      variables: { cabinReservationId: action.payload },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(clearUnconfirmedExtrasCall);
    const clearUnconfirmedExtras = response.data?.clearUnconfirmedExtras;

    if (!clearUnconfirmedExtras?.success) {
      throw Error("Failed to clear unconfirmed extras");
    }
  } catch (error) {
    // appInsights.trackException(error);
    console.log(error);
  }
}

export default function* stickySagas() {
  yield all([
    takeEvery(
      (action: any) => /bookingSearch\/SET\/\w*$/.test(action.type),
      resetSticky,
    ),
    takeEvery(
      "siteSettings/confirmActiveBookingStepAction",
      checkForBookingConfirmationSaga,
    ),
    takeEvery("sticky/removeExtraSticky", cancelExtraBasketSaga),
  ]);
}
