import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = null;
export const initializeCabinSlice = createSlice({
  name: "initialize",
  initialState,
  reducers: {
    initializeAddCabin: (
      state,
      action: PayloadAction<{ [x: string]: string | boolean }>,
    ) => {
      state;
      state.experiments = action.payload;
    },
  },
});

export const { initializeAddCabin } = initializeCabinSlice.actions;
